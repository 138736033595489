<template>
  <h1>About ...</h1>
</template>

<script>
export default {
  name: "About",
};
</script>

<style></style>
